<template>
<div>

  <!-- lighthouse video and any text and buttons that are on the video -->
  <div class="video-text-container">
    <video :class="$vuetify.display.smAndDown ? 'lighthouse-video--mobile' : 'lighthouse-video'" id="video" autoplay muted loop>
      <source :src="`${$imageSrc}homepage-lighthouse.mp4`" type="video/mp4" >
    </video>
    <v-col
      :cols="$vuetify.display.smAndDown ? 12 : 5"
      :class="{
        'text-left c-text--white': true,
        'text-on-video': $vuetify.display.mdAndUp,
        'text-on-video--mobile': $vuetify.display.smAndDown
      }"
    >
      <!-- text on lighthous video -->
      <div class='mk-text--h1 mk-text--semibold'>Make informed security decisions</div>
      <div class="mk-text--h3 m-spacing--top-6">
        MITRE Engenuity ATT&CK® Evaluations is your resource for understanding how security solution
        providers might protect your organization's unique needs against known adversaries
      </div>
      <!-- start using evaluations button -->
      <v-btn
        v-if="$vuetify.display.mdAndUp"
        :class="{
          'start-using-evaluations-button mk-button--primary-ghost': true,
          'm-spacing--top-6': $vuetify.display.mdAndUp,
          'start-using-evaluations-button--mobile': $vuetify.display.smAndDown
        }"
        :to="`/results/managed-services/?scenario=1&evaluation=menupass-blackcat`"
        pendo-id="home.evaluationtabs.startusingevaluationsbutton"
      >
        See latest evaluations
      </v-btn>
    </v-col>
    <!-- start using evaluations button is on the bottom of the lighthouse video if it is in mobile view -->
    <v-col class="start-using-evaluations-button--col">
      <v-btn v-if="$vuetify.display.smAndDown"
        class='mk-button--primary-ghost start-using-evaluations-button--mobile'
          :to="`/results/managed-services/?scenario=1&evaluation=menupass-blackcat`"
          pendo-id="home.evaluationtabs.startusingevaluationsbutton"
        >
          See latest evaluations
      </v-btn>
    </v-col>
    <!-- evaluation tabs -->
    <div class="evaluation-tabs">
      <v-row v-if="$vuetify.display.mdAndUp" id="evaluation-tabs">
        <v-col
          :class="{
            'evaluation-tab c-text--cursor-pointer': true,
            'evaluation-tab--active': selectedEvaluationTab.name === evaluation.name,
            'evaluation-tab--inactive': selectedEvaluationTab.name !== evaluation.name}"
          v-for="evaluation in displayedEvaluations"
          :value="evaluation.name"
          :key="evaluation.name"
          @click="selectEvaluation(evaluation)"
          data-id="home.evaluationtabs.evaluationtab"
        >
          <div class="m-spacing--left-9 m-spacing--top-7 m-spacing--bottom-5">
            <div class="mk-text--black text-left m-spacing--bottom-3 mk-text--h4 mk-text--semibold">
              {{evaluation.display_name}} ({{getYear(evaluation)}})
              <sup v-if="evaluation === displayedEvaluations[0]" class="c-text--primary mk-text--semibold mk-text--caption">NEW</sup>
            </div>
            <div class="mk-text--grey90 mk-text--body text-left">{{getDomainDisplayName(evaluation.domain)}}</div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>

  <!-- evaluation select for xs screen size -->
  <v-col class="col--more-x-padding" v-if="$vuetify.display.smAndDown">
    <VueMultiselect
      :options="displayedEvaluations"
      label="display_name"
      placeholder="Select evaluation"
      class="mk-vue-select"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      v-model="selectedEvaluationTab"
      data-id="home.evaluationtabsmobile.evaluationselect"
      >
    </VueMultiselect>
  </v-col>

  <!-- information about selected evaluation -->
  <v-col v-if="selectedEvaluationTab" id="evaluation-description">
    <v-row class="col--more-x-padding">
      <v-col class="m-spacing--top-9" align="center" v-if="$vuetify.display.md">
        <EvaluationLogo :selectedEvaluationTab="selectedEvaluationTab" :getDomainURL="getDomainURL" />
      </v-col>
      <v-col class="text-left" :cols="$vuetify.display.mdAndDown ? 12 : 6">
        <div class="mk-text--h3 m-spacing--top-9">
          {{selectedEvaluationTab.display_name}} ({{getYear(selectedEvaluationTab)}}) <sup v-if="selectedEvaluationTab === displayedEvaluations[0]" class="c-text--primary">NEW</sup>
        </div>
        <div class="mk-text--grey60">{{getDomainDisplayName(selectedEvaluationTab.domain)}}</div>
        <p v-html="selectedEvaluationTab.description"></p>
        <v-row v-if="selectedEvaluationTab.name === 'er6'">
          <v-col :cols="$vuetify.display.mdAndDown ? 12 : 4">
            <v-btn
              class="m-spacing--top-4 mk-button--primary-ghost btn--full-col"
              width=180
              :to="`/${getDomainURL(selectedEvaluationTab.domain)}/${selectedEvaluationTab.name}/`"
              data-id="home.evaluationtabs.learnmorelink"
            >
              Learn more
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col :cols="$vuetify.display.mdAndDown ? 12 : 4">
            <v-btn
              class="m-spacing--top-4 mk-button--primary-ghost btn--full-col"
              width=180
              :to="selectedEvaluationTab.domain.toLowerCase() !== 'enterprise' && selectedEvaluationTab.domain.toLowerCase() !== 'managed_services' ?
                  `/${getDomainURL(selectedEvaluationTab.domain)}/participants/` :
                  `/results/${getDomainURL(selectedEvaluationTab.domain.toLowerCase())}/?scenario=1&evaluation=${selectedEvaluationTab.name}`"
              data-id="home.evaluationtabs.showresultsbutton"
            >
              Show results
            </v-btn>
          </v-col>
          <v-col :cols="$vuetify.display.mdAndDown ? 12 : 4">
            <v-btn
              data-id="home.evaluationtabs.learnmorelink"
              :class="{
                'mk-button--primary-link btn--full-col': true,
                'ml-1  m-spacing--top-4': $vuetify.display.lgAndUp
              }"
              elevation="0"
              :to="`/${getDomainURL(selectedEvaluationTab.domain)}/${selectedEvaluationTab.name}/`"
                >Learn more</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- evaluation logo -->
      <v-col class="m-spacing--top-9 evaluation-logo-col" align="right" v-if="$vuetify.display.lgAndUp">
        <EvaluationLogo :selectedEvaluationTab="selectedEvaluationTab" :getDomainURL="getDomainURL" />
      </v-col>
    </v-row>
    <v-divider class="m-spacing--top-9"></v-divider>
    <!-- participant logo slide -->
    <v-row>
      <v-col :class="{'slide-group-col': true, 'col--more-x-padding': $vuetify.display.mdAndUp}" v-if="$vuetify.display.smAndUp">
        <v-slide-group class="participant-slide-group" show-arrows="always">
          <template v-slot:next>
            <v-icon
              color="primary"
              size="x-large"
              icon="mdi-chevron-right"
              data-id="home.evaluationtabs.slidegroup.nextbutton"
              >
            </v-icon>
          </template>
          <template v-slot:prev>
            <v-icon
              color="primary"
              size="x-large"
              icon="mdi-chevron-left"
              data-id="home.evaluationtabs.slidegroup.previousbutton"
              >
            </v-icon>
          </template>
          <v-row justify="center" align="center">
            <v-slide-group-item
              v-for="p in selectedEvaluationTab.participants"
              :key="p.participant.name"
            >
              <v-img
                :class="{'ma-3': $vuetify.display.mdAndUp, 'pa-8 c-text--cursor-pointer': true}"
                :height="130"
                :width="170"
                contain
                eager
                :src="$imageSrc + p.participant.logo_image"
                @click="routeToParticipant(p.participant.name)"
                data-id="home.evaluationtabs.participantlogolink"
              ></v-img>
            </v-slide-group-item>
          </v-row>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-col>

</div>
</template>

<script>
import { getURLFromDomain } from "../../mixins";
import VueMultiselect from "vue-multiselect";
import { useRouter } from 'vue-router';
import EvaluationLogo from './EvaluationLogo.vue';

export default {
  name: "EvaluationTabs",
  components: {
    VueMultiselect,
    EvaluationLogo
  },
  data() {
    return {
      selectedEvaluationTab: undefined,
      evaluations: [],
      currentYear: new Date().getFullYear(),
      router: useRouter(),
      displayedEvaluations: [],
    };
  },
  watch: {
    selectedEvaluationTab(newValue){
      if (newValue.name === 'turla'){
        this.addNoResultsParticipantsTurla();
      }
      else if(newValue.name === 'oilrig'){
        this.addNoResultsParticipantsOilrig();
      }
    }
  },
  computed: {
    linkView () {
      if (this.selectedEvaluationTab.domain.toLowerCase() === "deceptions") {
        return "?view=deceptions";
      }
      else {
        return this.selectedEvaluationTab.domain.toLowerCase() === "managed_services" ? "?view=managed-services" : "?view=overview";
      }
    },
  },
  methods: {
    routeToParticipant(participantName) {
      let route = this.selectedEvaluationTab.domain.toLowerCase() !== 'enterprise' && this.selectedEvaluationTab.domain.toLowerCase() !== "managed_services" ?
                      `/${this.getDomainURL(this.selectedEvaluationTab.domain)}/participants/${participantName}${this.linkView}` :
                      `/results/${this.getDomainURL(this.selectedEvaluationTab.domain.toLowerCase())}/?vendor=${participantName}&scenario=1&evaluation=${this.selectedEvaluationTab.name}`;

      this.router.push(route);
    },
    selectEvaluation(evaluation) {
      this.selectedEvaluationTab = evaluation;
      let evaluationDescription = document.getElementById('evaluation-description');
      let bottomEvaluationDescription = evaluationDescription.getBoundingClientRect().bottom;
      if (bottomEvaluationDescription > window.innerHeight){
        let scrollToElement = evaluationDescription;
        let headerHeight = 100;
        let evaluationTabHeight = 100;
        let topOfElement = scrollToElement.getBoundingClientRect().top + window.scrollY - headerHeight - evaluationTabHeight;
        window.scrollTo({top: topOfElement, behavior: 'smooth'});
      }
    },
    addNoResultsParticipantsOilrig(){
      if(this.selectedEvaluationTab.participants.find(p => p.participant.name === 'trendmicro') === undefined) {
        this.$api
          .get(`participants/?name=trendmicro`).then(response => {
            let trendmicro = {};
            trendmicro['participant'] = response.data[0]
            this.selectedEvaluationTab.participants.push(trendmicro)
            this.selectedEvaluationTab.participants = this.selectedEvaluationTab.participants.sort(function(a,b) {
              return (a['participant'].name > b['participant'].name ? 1 : -1)
            });
          })
      }
    },
    addNoResultsParticipantsTurla(){
      if(this.selectedEvaluationTab.participants.find(p => p.participant.name === 'cisco') === undefined) {
        this.$api
          .get(`participants/?name=cisco`).then(response => {
            let cisco = {};
            cisco['participant'] = response.data[0];
            this.selectedEvaluationTab.participants.push(cisco);
            this.selectedEvaluationTab.participants = this.selectedEvaluationTab.participants.sort(function(a,b) {
              return (a['participant'].name > b['participant'].name ? 1 : -1)
            });
          })
      }
      if(this.selectedEvaluationTab.participants.find(p => p.participant.name === 'checkpoint') === undefined) {
        this.$api
          .get(`participants/?name=checkpoint`).then(response => {
            let checkpoint = {};
            checkpoint['participant'] = response.data[0];
            this.selectedEvaluationTab.participants.push(checkpoint);
            this.selectedEvaluationTab.participants = this.selectedEvaluationTab.participants.sort(function(a,b) {
              return (a['participant'].name > b['participant'].name ? 1 : -1)
            });
          })
      }
    },
    getDomainDisplayName(domain){
      return getURLFromDomain(domain).domain_label;
    },
    getDomainURL(domain){
      return getURLFromDomain(domain).urlDomain;
    },
    getYear(evaluation){
      return evaluation.date_published ? evaluation.date_published.slice(0, 4) : this.currentYear;
    },
    getEvaluations(){
      this.$api.get("adversaries/").then(response => {
        // remove or statement before release
        let evaluations = response.data.filter(evaluation => evaluation.status === "PUBLISHED" && evaluation.date_published || evaluation.name === 'er6');
        evaluations = evaluations.sort((a,b) => {
          let aDatePublished = new Date(a.date_published);
          let bDatePublished = new Date(b.date_published);
          return bDatePublished - aDatePublished
        })
        // move er6 to array position 1 for msr2 release
        evaluations.splice(1, 0, evaluations.splice(evaluations.indexOf('er6'), 1)[0]);
        this.evaluations = evaluations;
        this.displayedEvaluations = this.$vuetify.display.md ? evaluations.slice(0,2) : evaluations.slice(0, 3);
        this.selectedEvaluationTab = evaluations[0];
      })
    },
    handlePageResize(){
      this.displayedEvaluations = this.$vuetify.display.md ? this.evaluations.slice(0,2) : this.evaluations.slice(0, 3);
    }
  },
  mounted(){
    this.getEvaluations();
    window.addEventListener("resize", this.handlePageResize);
  },
  unmounted(){
    window.removeEventListener("resize", this.handlePageResize)
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/pages/_evaluation-tabs.scss";
</style>
