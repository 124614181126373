<template>
  <a
    :href="`/${getDomainURL(selectedEvaluationTab.domain)}/${selectedEvaluationTab.name}`"
    target=""
    rel="noopener"
    data-id="home.evaluationtabs.evaluationlogolink"
    >
      <v-img
        :src="$imageSrc + selectedEvaluationTab.logo_image"
        max-width="200"
        width="100%"
        contain
      ></v-img>
  </a>
</template>

<script>
  export default {
    name: "EvaluationLogo",
    props: {
      selectedEvaluationTab: Object,
      getDomainURL: Function,
    }
  }
</script>