<template>
  <v-row justify="center" align="center" class="questions-row">
    <v-col cols="8">
      <v-card elevation="0" color="background" class="m-spacing--top-5 m-spacing--bottom-5 px-10">
        <v-row align="center" justify="center" class="py-4 m-spacing--top-7 m-spacing--bottom-7">
          <v-col class="text-center" v-if="$vuetify.display.lgAndUp">
            <v-icon
              class="mr-4"
              icon="mdi-comment-question"
              color="primary"
              size="100"
            ></v-icon>
          </v-col>
          <v-col align="left">
            <div class="mk-text--h2 mk-text--semibold mk-text--black">Questions?</div>
            <div class="mk-text--h3 mk-text--regular mk-text--grey60 m-spacing--top-3">Our team is ready to help you</div>
            <v-row>
              <v-col :cols="$vuetify.display.mdAndDown ? 12 : 3">
                <v-btn
                  :class="{'m-spacing--top-5 mk-button--primary-ghost': true, 'btn--full-col': $vuetify.display.mdAndDown}"
                  width=110
                  href="/contact-us/"
                  data-id="home.questions.contactusbutton"
                >
                  Contact
                </v-btn>
              </v-col>
              <v-col :cols="$vuetify.display.mdAndDown ? 12 : 3">
                <v-btn
                  :class="{
                    'mk-button--primary-link btn--full-col': true,
                    'ml-1  m-spacing--top-4': $vuetify.display.lgAndUp
                  }"
                  elevation="0"
                  href="/get-updates/"
                      >
                  Get updates
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>