<template>
  <EvaluationTabs />
  <Methodology theme="dark" />
  <Questions />
</template>

<script>
import EvaluationTabs from './EvaluationTabs.vue';
import Methodology from '../Methodology.vue';
import Questions from './Questions.vue';
export default {
  name: 'Home',
  data() {
    return {};
  },
  components: {
    EvaluationTabs,
    Methodology,
    Questions,
  },
};
</script>